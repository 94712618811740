.ellipse-main-container {
  display: flex;
  align-items: center;
  height: 560px;
  overflow: hidden;
}

.ellipse-main {
  margin: 0 auto;
  width: 480px;
  height: 480px;
  position: relative;
}

.big-circle {
  height: 100%;
  width: 100%;
  position: relative;
  box-shadow: 0px 4px 99.1px 0px #D2D3FF;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(250, 250, 243, 0) 14.78%, #BBBCFF 100.21%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: Rotate 20s linear infinite;
}

.icon-block {
  width: 64px;
  height: 64px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-block img {
  margin: 0 auto;
  width: 90%;
  animation: Rotate-reverse 20s linear infinite;
}

.icon-block:first-child {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon-block:nth-child(2) {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.icon-block:nth-child(3) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}

.icon-block:nth-child(4) {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.circle {
  animation: circle-rotate 20s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 75%;
  height: 75%;
  box-shadow: 0px 4px 99.1px 0px #D2D3FF;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(250, 250, 243, 0) 14.78%, #BBBCFF 100.21%);
  border-radius: 50%;
}

.mini-circle {
  animation: circle-rotate 20s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 50%;
  height: 50%;
  box-shadow: 0px 4px 99.1px 0px #D2D3FF;
  border: 1px solid;
  border-image-source: linear-gradient(180deg, rgba(250, 250, 243, 0) 14.78%, #BBBCFF 100.21%);
  border-radius: 50%;
}

@keyframes Rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Rotate-reverse {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes circle-rotate {
  from {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(405deg);
  }
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #242552;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.icon-block:hover .tooltip {
  opacity: 1;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #242552 transparent transparent transparent;
  font-size: 13px;
  font-weight: 700;
}

.icon-block:nth-child(2):hover .tooltip {
  background-color: #BBBCFF;
  color: #000;
}

.icon-block:nth-child(2):hover .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #BBBCFF transparent transparent transparent;
  font-size: 13px;
  font-weight: 700;
}

@media(max-width:768px) {
  .ellipse-main-container {
    min-height: auto;
    display: flex;
    align-items: center;
    height: 360px;
    overflow: hidden;
    margin: 50px 0;
  }
  .icon-block img{
    width: 70%;
  }
  .ellipse-main {
    margin: 0 auto;
    width: 300px;
    height: 300px;
    position: relative;
  }
  .big-circle{
    box-shadow: none;
  }
}

@media(min-width:768px) and (max-width:992px) {
  .ellipse-main-container {
    min-height: auto;
    display: flex;
    align-items: center;
    height: 560px;
    overflow: hidden;
    margin: 100px 0;
  }
  
}