* {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

:root {
    --primary-dark: #000000;
    --primary-color: #141432;
    --primary-blue: #242552;
    --light-gray: #F6F7F9;
    --white: #ffffff;
    --font-inter: 'Inter';
    --font-axiforma: 'Axiforma';
    --font-roboto: 'Roboto';
    --font-sora: 'Sora';
    --font-lato: 'Lato'
}

/* src/fonts.css */
@font-face {
    font-family: 'Axiforma';
    src: url('../../../public/assets/css/Axiforma/fonnts.com-Axiforma_Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../../../public/assets/css/Inter/Inter_18pt-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../../../public/assets/css/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sora';
    src: url('../../../public/assets/css/Sora/Sora-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../../../public/assets/css/Lato/Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


/* Demo page */
/* Navbar */
.navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-responsive {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main-layout .navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--white) !important;
    height: 92px;
}

.main-layout .navbar-nav .nav-item.active a.nav-link {
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 700;
}

.investment-tabs-container .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link{
    width: 100% !important;
}

.main-layout .navbar-nav li a.nav-link {
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 500;
    color: #242552;
    margin: 0 10px;
}

.main-layout .nav-link {
    color: #242552;
}

.main-layout .nav-link.active {
    color: var(--primary-blue);
}

.main-layout .navbar-collapse {
    flex-grow: 1;
    justify-content: space-between;
    background-color: var(--white);
}

.main-layout .navbar-right-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    padding: 10px 20px;
    margin-bottom: 0;
}

.main-layout .navbar-right-nav .link {
    background: none !important;
    box-shadow: none !important;
}

.nav-link.login {
    box-shadow: none;
    background: transparent;
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 500;
}

.main-layout .navbar-right-nav .nav-item .nav-link {
    font-size: 14px;
}

.main-layout .navbar-right-nav li i {
    font-size: 22px;
}

.main-layout .nav-button {
    background: #242552;
    color: var(--white);
    border-radius: 8px;
    padding: 7px 14px;
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    margin-bottom: 0;
}

/* End Navbar */

/* Future of Startups */
.main-layout .future-of-startups-contect {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
.main-layout .nav-button1{
    color: #242552;
    border-radius: 8px;
    padding: 7px 14px;
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    background-color: white;
    margin-bottom: 0;
}
.main-layout .hero-section .future-of-startups{
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 87vh;
}
.main-layout .future-of-startups h1 {
    font-family: var(--font-inter);
    font-size: 40px;
    font-weight: 700;
    line-height: 63px;
    color: #242552;
}

.main-layout .future-of-startups h1 span {
    font-family: var(--font-inter);
    font-size: 40px;
    font-weight: 700;
    line-height: 63px;
    background: linear-gradient(90.66deg, #5153B8 2.82%, #9FB4FF 56.58%, #D2DCFF 89.22%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.main-layout .future-of-startups p {
    font-family: var(--font-inter);
    font-size: 19.84px;
    font-weight: 400;
    color: #7E7E7E;
    line-height: 28px;
}

.main-layout .future-of-startups .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.main-layout .future-of-startups .start-investing-btn {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 700;
    background-color: var(--primary-blue);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    margin-right: 20px;
}

.main-layout .future-of-startups .enroll-startup-btn {
    border: 1px solid var(--primary-blue);
    background-color: var(--white);
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 500;
    color: #171717;
    padding: 10px 20px;
    border-radius: 8px;
}

.main-layout .hero-banner-img {
    height: 87vh;
    width: 100%;
    object-fit: cover;
}

/* End Future of Startups */
/* Investor section */
.investor-section {
    padding: 30px 0;
}

.investor-section .heading {
    font-size: 24px;
    font-weight: 400;
    line-height: 63px;
    color: #242552;
    font-family: var(--font-sora);
}

.investor-section .subheading {
    font-family: var(--font-sora);
    font-size: 64px;
    font-weight: 600;
    line-height: 63px;
    color: #242552;
}

.investor-section .subheading.gradient {
    background: linear-gradient(90deg, #5153B8 0%, #9FB4FF 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.investor-section .subtext {
    font-family: var(--font-sora);
    font-size: 24px;
    font-weight: 400;
    line-height: 63px;
    color: #949494;
}

.investor-section .investor-data {
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: #8B8B8B;
    background-color: #fff;
    border: 1px solid #D7D8FF;
    border-radius: 7px;
    padding: 12px;
    margin: 20px 20px 20px 0;
}

.investment-section .investor-data:hover {
    cursor: pointer;
    border: 1px solid #242552 !important;
}

.investor-section {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(180deg, #FFFFFF -1.61%, #E8EDFE 90.28%, #FFFFFF 113.38%);
}



.investor-scroll-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.investor-scroll {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.investor-data {
    font-size: 12px;
    font-weight: 400;
    color: #8B8B8B;
    background-color: #fff;
    border: 1px solid #D7D8FF;
    border-radius: 7px;
    padding: 12px;
    margin: 20px 20px 20px 0;
    white-space: nowrap;
    display: inline-flex;
    flex-shrink: 0;
}

@keyframes scrollLeftToRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes scrollRightToLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}

.scroll-left {
    animation: scrollLeftToRight 50s linear infinite;
    animation-delay: -25s; /* Start at 50% by delaying half the animation duration */
}

.scroll-right {
    display: inline-flex;
    animation: scrollRightToLeft 50s linear infinite;
}

/* End Investor */
/* Fund section */
.fund-section {
    margin: 0px 0;
    padding: 30px 40px 30px 40px;
    background-color: var(--white);
}
.home-fund-section{
    margin: 0px 0;
    padding: 20px 20px;
    background-color: var(--white);
}
.fund-section .carousel-control-prev {
    opacity: 1;
    width: 5%;
    position: relative;
}
.fund-section .carousel-control-next {
    opacity: 1;
    width: 5%;
    position: relative;
}
.fund-section .heading {
    font-family: var(--font-roboto);
    font-size: 36px;
    font-weight: 700;
    
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fund-section .head {
    font-family: var(--font-inter);
    font-size: 32px;
    font-weight: 500;
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 50px;
}

.fund-section .content {
    font-size: 20px;
    font-weight: 500;
    color: #8E8E8E;
    margin-bottom: 0;
}
.fund-section .carousel{
    display: flex;
    margin-top: 30px;
}
.mb-5{
    margin-bottom: 80px !important;
}
.fund-section .card {
    /* border: 1px solid #DADADA; */
    border: none;
    padding: 10px;
    border-radius: 16px;
}

.fund-section .card-img {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 450px;
    object-fit: contain;
    width: 100%;
}
.profitsection-slider-image{
    height: 500px;
}

.fund-section .subcontent {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-dark);
    margin-bottom: 50px;
}

.fund-section label {
    font-size: 15px;
    font-weight: 700;
    color: #3C3E86;
    background-color: #EBECFF;
    border-radius: 3.5px;
    padding: 3px 5px;
    margin-right: 10px;
    margin-top: 10px;
}

.fund-section .fund-details {
    font-family: var(--poppins);
    font-size: 16px;
    font-weight: 500;
    color: #242552;
}

.fund-section .fund-details span {
    color: #BEBEBE;
}

.job-roles .carousel-inner {
    border-radius: 19px;
    background: var(--white);
}

.job-roles .carousel-control-next {
    opacity: 1;
}

.job-roles .carousel-control-next-icon {
    color: var(--primary-color);
    background-color: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
}



.job-roles .job-roles-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 25px 50px 10px 25px;
    background: var(--white);
}

.job-roles .job-roles-cards .card {
    box-shadow: 2px 2px 10.4px 0px #A3A3A340;
    border: 1px solid #E4E4E4;
    padding: 20px;
    border-radius: 14px;
    margin-bottom: 30px;
}

.job-roles .job-roles-cards .card p {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-dark);
}

.carousel-indicators [data-bs-target] {
    height: 7px;
    width: 20px;
    border-radius: 2px;
    border: none;
    background: linear-gradient(90deg, rgba(0, 167, 172, 0.41) 0%, rgba(36, 37, 82, 0.41) 100%);
}

.carousel-indicators .active {
    width: 35px;
    height: 7px;
    border-radius: 2px;
    border: none;
    background: linear-gradient(270.04deg, #00A7AC 0.03%, #01579F 99.97%);
}

/* End Fund section */
/* profit section */
.profit-section {
    margin: 30px 0;
    padding: 0px 40px 30px 40px;
    background-color: var(--white);
}

.profit-section .heading {
    font-family: var(--font-roboto);
    font-size: 36px;
    font-weight: 700;
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.profit-section .content {
    font-size: 20px;
    font-weight: 500;
    color: #A3A3A3;
    margin-bottom: 0;
}

.profit-section .subheading {
    font-family: var(--font-sora);
    font-size: 30px;
    font-weight: 700;
    color: #242552;
}

.profit-section .subcontent {
    font-size: 19.84px;
    font-weight: 400;
    color: #7E7E7E;
}

.profit-section .btn {
    background-color: var(--primary-blue);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    font-family: var(--font-inter);
}

.profit-section .profit-content {
    margin: 50px 0;
}

.profit-section .profit-content .content-heading {
    font-family: var(--font-lato);
    font-size: 25px;
    font-weight: 700;
    font-family: var(--font-inter);
    letter-spacing: normal;
    line-height: 32.89px;
    color: #E33845;
    width: 70%;
    margin: 30px auto 20px;
    text-align: center;
}

.profit-section .profit-content label {
    font-family: var(--font-lato);
    font-size: 15px;
    font-weight: 700;
    color: #656565;
}

.profit-section .profit-content .arrow {
    min-height: 50px;
    display: flex;
    align-items: center;
}

.profit-section .profit-content input[type="text"] {
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    padding: 15px 30px;
    font-family: var(--font-lato);
    font-size: 18px;
    font-weight: 700;
    color: #989898;
    min-height: 50px;
}

.profit-section .profit-content .btn {
    background-color: var(--primary-blue);
    color: var(--white);
    padding: 15px 40px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 700;
    font-family: var(--font-lato);
}

.profit-section .profit-content .carousel-indicators {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: 0;
    bottom: -60px;
}
.profit-section .profit-content .carousel-control-prev {
    opacity: 1;
    width: 5%;
    position: relative;
}
.profit-section .profit-content .carousel-inner .carousel{
    display: flex;
    gap: 30px;
}
.profit-section .profit-content .carousel-control-next {
    opacity: 1;
    width: 5%;
    position: relative;
}
.profit-section .profit-content .carousel-control-prev-icon,
.profit-section .profit-content .carousel-control-next-icon {
    background-image: none;
}

.profit-section .profit-content .carousel-indicators [data-bs-target] {
    height: 7px;
    width: 12px;
    border-radius: 2px;
    border: none;
    background: linear-gradient(90deg, var(--primary-blue) 0%, var(--primary-blue) 100%);
}

.profit-section .profit-content .carousel-indicators .active {
    width: 35px;
    height: 7px;
    border-radius: 2px;
    border: none;
    background: linear-gradient(90deg, var(--primary-blue) 0%, var(--primary-blue) 100%);
}


/* End Profit */
/* Local Favourite Section*/
.local-favourite-section {
    margin: 30px 0 0 0;
    padding: 0px 40px 10px 40px;
    background-color: var(--white);
}

.local-favourite-section .heading {
    font-family: var(--font-sora);
    font-size: 36px;
    font-weight: 700;
    color: #242552;
    width: 60%;
    margin: auto;
}

.local-favourite-section .heading span {
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.local-favourite-section .content {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 500;
    color: #A3A3A3;
    margin-bottom: 0;
}

.local-favourite-section .local-favourite-section-content {
    margin-top: 100px;
}


.local-favourite-section .subheading {
    font-family: var(--font-inter);
    font-size: 24px;
    font-weight: 500;
    color: #A3A3A3;
}

.local-favourite-section .local-favourite-section-content .label {
    border: 1px solid #E6E6E6;
    border-radius: 9px;
    padding: 5px 20px;
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-dark);
    margin: 10px 20px 10px 0;
}

.local-favourite-section .local-favourite-section-content .card {
    border-radius: 19px;
    width: 70%;
    border: none;
    margin-left: auto;
}

.local-favourite-section .local-favourite-section-content .card .card-img {
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.local-favourite-section .local-favourite-section-content .card .plus-img {
    height: 50px;
}

.local-favourite-section .local-favourite-section-content .medi-content {
    font-size: 24px;
    font-weight: 700;
    color: #014181;
    display: flex;
    align-items: center;
}

.local-favourite-section .local-favourite-section-content .card-content {
    font-family: var(--font-inter);
    font-size: 15px;
    font-weight: 500;
    color: #949494;
}

.local-favourite-section .local-favourite-section-content .card-bottom-content {
    padding: 20px;
}

.local-favourite-section .local-favourite-section-content .card-bottom-content .investors {
    font-family: var(--poppins);
    font-size: 24px;
    font-weight: 700;
    color: #242552;
}

.local-favourite-section .local-favourite-section-content .card-bottom-content .investors-content {
    font-family: var(--poppins);
    font-size: 13px;
    font-weight: 400;
    color: #A7A7A7;
}

.local-favourite-section .btn {
    background-color: var(--primary-blue);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-inter);
}

.local-favourite-section .carousel-indicators {
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: 0;
    bottom: -50px;
}

.local-favourite-section .carousel-indicators [data-bs-target] {
    height: 7px;
    width: 12px;
    border-radius: 2px;
    border: none;
    background: linear-gradient(90deg, rgba(187, 188, 255, 0.69) 0%, rgba(36, 37, 82, 0.69) 100%);
}

.local-favourite-section .carousel-indicators .active {
    width: 35px;
    height: 7px;
    border-radius: 2px;
    border: none;
    background: linear-gradient(90deg, rgba(187, 188, 255, 0.69) 0%, rgba(36, 37, 82, 0.69) 100%);
}

/* WhyBizdateUp section */
.why-bizdateup-section {
    margin: 30px 0;
    padding: 0px 40px 30px 40px;
    background-color: var(--white);
}

.why-bizdateup-section .heading {
    font-family: var(--font-sora);
    font-size: 36px;
    font-weight: 700;
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 60%;
    margin: auto;
}

.why-bizdateup-section .heading span {
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.why-bizdateup-section .content {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 500;
    color: #A3A3A3;
    margin-bottom: 0;
}

.why-bizdateup-section .subheading {
    font-family: var(--font-sora);
    font-size: 20px;
    font-weight: 700;
    line-height: 34px;
    color: #242552;
    width: 60%;
    margin: auto;
}

.why-bizdateup-section .subcontent {
    font-family: var(--font-inter);
    font-size: 15px;
    font-weight: 500;
    color: #5f5f5f;
    line-height: 23px;
    width: 85%;
    margin: auto;
}

/* End WhyBizDateUp section */
/* Risk Section */
.risk-section {
    margin: 30px 0;
    padding: 0px 40px 30px 40px;
    background-color: var(--white);
}

.risk-section .subheading {
    font-family: var(--font-sora);
    font-size: 30px;
    font-weight: 700;
    background: linear-gradient(92.34deg, #3C3E86 3.5%, #BBBCFF 98.04%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.risk-section .subcontent {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 400;
    color: #7E7E7E;
}

.risk-section .btn {
    background-color: var(--primary-blue);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--font-inter);
}

/* End Risk Section */
/* Investment Section */
.investment-section {
    margin: 30px 0;
    padding: 40px 200px;
    background-color: var(--white);
}

.investment-section .heading {
    font-family: var(--font-sora);
    font-size: 36px;
    font-weight: 700;
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
/* 
.investment-section .img {
    height: 350px;
} */

.investment-section .content {
    font-size: 20px;
    font-weight: 500;
    color: #A3A3A3;
    margin-bottom: 0;
}

.investment-section .subheading {
    font-family: var(--font-inter);
    font-size: 32px;
    font-weight: 700;
    color: #242552;
}

.investment-section .subcontent {
    font-family: var(--font-inter);
    font-size: 15px;
    font-weight: 500;
    color: #5f5f5f;
    line-height: 20.46px;
}

/* End Investment Section */
/* Blog Section */
.blog-section {
    background: var(--white);
    margin: 60px 0;
}

.blog-section .head {
    font-family: var(--font-sora);
    font-size: 36px;
    font-weight: 700;
    line-height: 58px;
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.blog-section .head span {
    background: linear-gradient(92.03deg, #5153B8 34.84%, #BBBCFF 100.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.blog-section .recent-blog-posts {
    margin: 70px 0 30px 0;
}

.blog-section .recent-blog-posts .blogs-img {
    width: 100%;
    border-radius: 15px;
    height: 180px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

}


.blog-section .recent-blog-posts .blogs-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top;
}

.blog-section .recent-blog-posts .blogs-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.blog-section .recent-blog-posts .blogs-content h6.heading {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-blue);
}

.blog-section .recent-blog-posts .blogs-content h6.subheading {
    font-family: var(--font-inter);
    font-size: 15px;
    font-weight: 700;
    color: #1A1A1A;
    margin-top: 15px;
}

.blog-section .recent-blog-posts .content {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 400;
    color: #667085;
    width: 90%;
    margin-top: 5px;
}
.line{
    border: none; height: 2px; background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
}
.blog-section .recent-blog-posts .right-content {
    font-family: var(--font-inter);
    font-size: 15px;
    font-weight: 400;
    color: #667085;
    width: 80%;
    margin: auto;
    text-align: justify;
    margin-top: 5px;
}

.blog-section .recent-blog-posts .blogs-right-img {
    width: 100%;
    border-radius: 15px;
    height: 100%;
}

.blog-section .recent-blog-posts .blogs-right-img img {
    width: 95%;
    object-fit: cover;
    object-position: top;
    border-radius: 15px;
}

.blog-section-carousel .carousel-inner {
    border-radius: 19px;
    background: var(--white);
}
.blog-section-carousel .carousel-control-prev {
    opacity: 1;
    width: 5%;
    position: relative;
}
.blog-section-carousel .carousel{
    display: flex;
    gap: 30px;
}
.blog-section-carousel .carousel-control-next {
    opacity: 1;
    width: 5%;
    position: relative;
}
.fund-section .carousel-control-prev-icon {
    color: var(--primary-color);
    background-color: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
}
.blog-section-carousel .carousel-control-prev-icon {
    color: var(--primary-color);
    background-color: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
}

.blog-section-carousel .carousel-control-next-icon {
    color: var(--primary-color);
    background-color: #fff;
    border: 1px solid #DFDFDF;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
}



.blog-section-carousel .blog-section-carousel-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 25px 50px 10px 25px;
    background: var(--white);
}

.blog-section-carousel .blog-section-carousel-cards .card {
    box-shadow: 2px 2px 10.4px 0px #A3A3A340;
    border: 1px solid #E4E4E4;
    padding: 20px;
    border-radius: 14px;
    margin-bottom: 30px;
}

.blog-section-carousel .blog-section-carousel-cards .card p {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-dark);
}

/* End Blog Section */
/* Investment on BizDateUp */
.investments-on-bizdateup .subheading {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 600;
    color: #7E7E7E;
}
.investments-on-bizdateup{
    margin: 100px 0;
    background-color: var(--white);
}
.investments-on-bizdateup .heading {
    font-size: 30px;
    font-weight: 700;
    background: linear-gradient(92.34deg, #3C3E86 3.5%, #BBBCFF 98.04%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    width: 70%;
}

.investments-on-bizdateup .content {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 400;
    color: #7E7E7E;
}

.investments-on-bizdateup .nav {
    border-bottom: 0;
}

.investment-tabs-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;
}

.investment-tabs-container .tabs {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ddd;
}

.investment-tabs-container .tab-button {
    flex: 1;
    padding: 10px 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: #888;
    font-weight: bold;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-color 0.3s;
}

.investment-tabs-container .tab-button.active {
    color: #000;
    border-bottom: 2px solid #000;
}
.navbar-brand img{
    height: 100%;
    width: 170px;
}
.investment-tabs-container .tab-content {
    padding: 20px 0;
}

.investment-tabs-container .tab-pane {
    animation: fadeIn 0.3s ease-in-out;
}

.investment-tabs-container .nav-tabs {
    border-bottom: none;
}

.investment-tabs-container .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    border: none;
    color: #CCCCCC;
    font-family: var(--font-sora);
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid #E0E0E0;
    padding: 20px 25px;
    width: 70%;
    text-align: start;
}

.investment-tabs-container .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: none;
    color: #242552;
    font-family: var(--font-sora);
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid var(--primary-blue);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* End Investment on BizDateUp */
/* FAQ Section */
.faq-section {
    margin: 30px 0;
    padding: 0px 40px 30px 40px;
}

.faq-section .accordion {
    margin-top: 70px;
    padding: 0 50px;
}

.faq-section h1.heading {
    font-family: var(--font-roboto);
    font-size: 64px;
    font-weight: 700;
    line-height: 84px;
    background: linear-gradient(180deg, var(--primary-blue) 0%, #5153B8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    margin: 100px 0;
}

.faq-section .accordion-item {
    background-color: var(--white);
    border-radius: 24px;
    border: none;
    border: 1px solid #EAEAEA
}

.faq-section .accordion-button {
    font-family: var(--font-roboto);
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-blue);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    line-height: 34px;
    border: 1px solid #EAEAEA;
    box-shadow: none;
}

.faq-section .accordion-button:not(.collapsed) {
    box-shadow: 0px 4px 22.6px 0px #83838340;
}

.faq-section .accordion-button::after {
    background-image: none;
    margin-left: 0;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 4px 22.6px 0px #83838340;
}

.faq-section .accordion-body {
    font-family: var(--font-roboto);
    font-size: 20px;
    font-weight: 500;
    color: #667085;
    padding: 0 20px 35px 20px;
    width: 90%;
}

.accordion-button::after {
    display: none !important;
}

.faq-section .accordion-button,
.accordion-item:first-of-type>.accordion-header .accordion-button {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.faq-section .accordion-button img {
    appearance: none;
    border: none;
    outline: none;
}

.faq-section .accordion-button:not(.collapsed) {
    background-color: var(--white);
    box-shadow: none;
    border: none;
}

.faq-section .heading {
    font-family: var(--Jakarta-sans);
    font-size: 36px;
    font-weight: 600;
    color: #101828;
}

.faq-section .content {
    font-family: var(--Jakarta-sans);
    font-size: 20px;
    font-weight: 500;
    color: #667085;
}

.faq-section .any-question {
    border: 1px solid #D6D6D6;
    padding: 25px;
    border-radius: 16px;
    margin-top: 60px;
}

.faq-section .any-question .heading {
    font-family: var(--font-inter);
    font-size: 24px;
    font-weight: 400;
    color: #2E2E27;
}

.faq-section .any-question .content {
    font-family: var(--font-inter);
    font-size: 15.38px;
    font-weight: 400;
    color: #2E2E27;
    margin-top: 14px;
    margin-bottom: 0;
}

.faq-section .any-question .btn {
    background-color: var(--primary-color);
    color: var(--white);
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 400;
    padding: 7px 14px;
    margin: auto 0;
}

.accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
    border-radius: 24px;
}

/* End FAQ */

/* Footer */
.footer {
    padding: 100px 0 30px 0;
    background: linear-gradient(0deg, #FFFFFF -1.61%, #E8EDFE 90.28%, #FFFFFF 113.38%);
}

.footer .heading {
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: var(--primary-dark);
}

.footer .content {
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: var(--primary-dark);
}

.footer .icon {
    margin-right: 20px;
}

.footer .link {
    font-family: var(--font-poppins);
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-dark);
    text-decoration: underline;
    margin-right: 25px;
    margin-top: 50px;
    line-height: 21px;
}

.footer .copyright-text {
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 80px;
}

.investor-labels {
    margin-top: 0;
}

.investor-label .investor-data {
    font-family: var(--poppins);
    font-size: 12px;
    font-weight: 400;
    color: #8B8B8B;
    background-color: #fff;
    border: 1px solid #D7D8FF;
    border-radius: 7px;
    padding: 12px;
    margin: 20px 20px 20px 0;
}

.investor-label .investor-data:hover {
    cursor: pointer;
    font-family: var(--font-inter);
    font-size: 12px;
    font-weight: 600;
    color: var(--primary-blue);
    border: 1px solid var(--primary-blue);
}

/* Startup */
.main-layout .startup-hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(90deg, #FFFFFF 21.61%, #E8EDFE 50.28%, #FFFFFF 113.38%);
    backdrop-filter: blur(300px);
    margin-top: 50px;
}

.main-layout .hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 75vh;
    margin-top: 50px;
    background: linear-gradient(90deg, #FFFFFF 31.61%, #E8EDFE 50.28%, #FFFFFF 113.38%)
}

.main-layout .startup-hero h1 {
    font-family: var(--font-sora);
    font-size: 30px;
    font-weight: 700;
    line-height: 63px;
    color: #242552;
}

.main-layout .startup-hero p {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 400;
    color: #7E7E7E;
    line-height: 28px;
}

.main-layout .startup-hero .buttons {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}

.main-layout .startup-hero .start-investing-btn {
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 500;
    background-color: var(--primary-blue);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    margin-right: 20px;
}

.main-layout .hero-banner-img {
    height: 87vh;
    width: 100%;
    object-fit: cover;
}

/* End Future of Startups */

/* Opportunities section */
.opportunities-section {
    margin: 0px 0;
    padding: 40px;
    background: linear-gradient(90deg, #FFFFFF 21.61%, #E8EDFE 50.28%, #FFFFFF 113.38%);
    min-height: 60vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.opportunities-section .heading {
    font-family: var(--font-sora);
    font-size: 36px;
    font-weight: 700;
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.opportunities-section .content {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 500;
    color: #A3A3A3;
    margin-bottom: 0;
}

.opportunities-section .subheading {
    font-family: var(--font-sora);
    font-size: 30px;
    font-weight: 700;
    color: #242552;
}

.opportunities-section .subcontent {
    font-family: var(--font-poppins);
    font-size: 19.84px;
    font-weight: 400;
    color: #7E7E7E;
}

.opportunities-section .btn {
    background-color: var(--primary-blue);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    font-family: var(--font-inter);
}

.opportunities-section .profit-content {
    margin-top: 100px;
}

/* End Opportunities section */

/* Portfolio Companies Section */
.portfolio-companies-section .card {
    /* border-radius: 16px; */
    border: none;
    /* padding: 20px; */
}

.portfolio-companies-section .head {
    font-family: var(--font-sora);
    font-size: 36px;
    font-weight: 700;
    line-height: 58px;
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.portfolio-companies-section .content {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 500;
    color: #8E8E8E;
    line-height: 38px;
    width: 70%;
    margin: auto;
}

.portfolio-companies-section .card img {
    opacity: 100%;
    border-radius: 16px;
    object-fit: cover;
    object-position: top;
}

.portfolio-companies-section .card .heading {
    font-family: var(--font-poppins);
    font-size: 20px;
    font-weight: 700;
    color: var(--primary-blue);
    margin-top: 20px;
}

.portfolio-companies-section .card .subheading {
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: 400;
    color: #A7A7A7;
}

.portfolio-companies-section .details {
    font-family: var(--font-poppins);
    font-size: 15px;
    font-weight: 400;
    color: #9E9E9E;
    margin-top: 25px;
    line-height: 22.5px;

}

/* End Portfolio companies section */

/* Deals Section */
.deals-section .card {
    border-radius: 16px;
    border: 1px solid #D6D6D6;
    padding: 20px;
}

.deals-section .head {
    font-family: var(--font-sora);
    font-size: 36px;
    font-weight: 700;
    line-height: 58px;
    color: #242552;
}

.deals-section .content {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 500;
    color: #8E8E8E;
    line-height: 38px;
    width: 70%;
    margin: auto;
}

.deals-section .card img {
    height: 200px;
    border-radius: 16px;
    object-fit: cover;
    object-position: top;
}

.deals-section .card .heading {
    font-family: var(--font-poppins);
    font-size: 20px;
    font-weight: 700;
    color: var(--primary-blue);
    margin-top: 20px;
}

.deals-section .card .subheading {
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: 400;
    color: #A7A7A7;
    margin-bottom: 0.5rem;
}

.deals-section .details {
    font-family: var(--font-poppins);
    font-size: 15px;
    font-weight: 400;
    color: #9E9E9E;
    margin-top: 5px;
    line-height: 22.5px;

}

/* End Deals section */

/* Syndicate Section */
.syndicate-section {
    margin: 30px 0;
    padding: 40px 200px;
    background-color: var(--white);
}
.head{
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.syndicate-section .heading {
    font-family: var(--font-sora);
    font-size: 36px;
    font-weight: 700;
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.contect-us-heading{
    background: linear-gradient(90.13deg, #3C3E86 -0.06%, #9FB4FF 99.89%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.syndicate-section .img {
    height: 400px;
}

.syndicate-section .content {
    font-size: 20px;
    font-weight: 500;
    color: #A3A3A3;
    margin-bottom: 0;
}

.syndicate-section .subheading {
    font-family: var(--font-inter);
    font-size: 32px;
    font-weight: 700;
    color: #242552;
    line-height: 38.73px;
}

.syndicate-section .subcontent {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 400;
    color: #242552;
    line-height: 24.2px;
}

/* End Syndicate Section */



.blogs-section .heading {
    font-family: var(--font-sora);
    font-size: 40px;
    font-weight: 700;
    background: linear-gradient(90.66deg, #5153B8 2.82%, #9FB4FF 56.58%, #D2DCFF 89.22%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.blogs-section .content {
    font-family: var(--font-poppins);
    font-size: 19.84px;
    font-weight: 400;
    line-height: 28px;
    color: #7E7E7E;
    margin-top: 30px;
}

.blog-labels {
    margin-top: 0;
}

.blog-label .blog-data {
    font-family: var(--font-inter);
    font-size: 12px;
    font-weight: 400;
    color: #8B8B8B;
    background-color: #fff;
    border: 1px solid #D7D8FF;
    border-radius: 7px;
    padding: 10px 12px;
    margin: 20px 20px 20px 0;
}

.blog-label .blog-data.active {
    font-family: var(--font-inter);
    font-size: 12px;
    font-weight: 600;
    background-color: #fff;
    border-radius: 7px;
    padding: 10px 12px;
    margin: 20px 20px 20px 0;
    cursor: pointer;
    color: var(--primary-blue);
    border: 1px solid var(--primary-blue);
}

.blog-label .blog-data:hover {
    cursor: pointer;
    font-family: var(--font-inter);
    font-size: 12px;
    font-weight: 600;
    color: var(--primary-blue);
    border: 1px solid var(--primary-blue);
}

.blog-label .nav-tabs {
    border-bottom: none;
}

.blog-label .nav-tabs .nav-link {
    border-bottom: none;
}

.blog-details .blog-content {
    border-bottom: 1px solid #E5E5E5;
    padding: 60px 0 40px 0;
}

.blog-details .profile-img {
    height: 50px;
    width: 50px;
}

.blog-details .profile-img img {
    border-radius: 100%;
    width: 100%;
    height: 100%;
}

.blog-details .img {
    border-radius: 16px;
    width: 100%;
}

.blog-details .blogger-writer {
    font-family: var(--font-inter);
    font-size: 24px;
    font-weight: 500;
    color: var(--primary-blue);
    margin: 0 20px;
}

.blog-details .date {
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 500;
    line-height: 63px;
    color: #9B9B9B;
    margin-bottom: 0;
}

.eading {
    font-family: var(--font-inter);
    font-size: 24px;
    font-weight: 500;
    color: var(--primary-blue);
    margin-top: 25px;
}

.blog-details .content {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 400;
    color: #949494;
    margin-top: 25px;
    line-height: 24px;
}

.blog-details .time {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 500;
    color: #616161;
}

.blog-details .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.investment-on-bizdateup{
    height: 500px;
    margin: auto;
}
.blog-details .reading-time {
    color: #616161;
    font-size: 0.9rem;
}

.blog-details .article-thumbnail {
    width: 80px;
    height: auto;
    border-radius: 8px;
}

.blog-details .thumbnail-img {
    height: 8px;
    width: 8px;
}

/* End Blogs Section */

/* Card Scroll functionality */
.auto-card-scroll .card-scroll-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 30px;
}

.auto-card-scroll .card-column {
    height: 80vh;
    overflow: hidden;
    width: auto;
    -webkit-mask-image: linear-gradient(var(--mask-direction, to right), transparent, #000 15%, #000 85%, transparent);
    --mask-direction: to bottom;
    overflow: hidden;
    mask-image: linear-gradient(var(--mask-direction, to right), transparent, #000 15%, #000 85%, transparent);
    transform: rotate(5deg);
    transform-origin: top center;
}

.auto-card-scroll .scroll-content {
    display: flex;
    flex-direction: column;
    animation-play-state: running;
    overflow: auto;
}

.auto-card-scroll .card-column.left .scroll-content {
    animation: scroll-up 400s linear infinite;
}

.auto-card-scroll .card-column.right .scroll-content {
    animation: scroll-down 400s linear infinite;
    animation-delay: -200s;
}

.auto-card-scroll .hero-cards {
    margin-bottom: 10px;
}

.auto-card-scroll .card-img {
    width: 300px;
    border-radius: 8px;
}

@keyframes scroll-up {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-100%);
    }
}

@keyframes scroll-down {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

.auto-card-scroll .card-column:hover .scroll-content {
    animation-play-state: paused;
}


.hero-cards {
    flex: 1 1 45%;
    box-sizing: border-box;
    min-width: 45%;
    max-width: 50%;
}

.hero-cards .card {
    border-radius: 19px;
    margin-left: auto;
    padding: 20px;
}

.hero-cards .card .card-img {
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.hero-cards .card .plus-img {
    height: 50px;
}

.hero-cards .card-content {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 500;
    color: #949494;
    margin-bottom: 0.5rem;
}

.hero-cards .card-bottom-content {
    padding: 20px;
}

.hero-cards .card-bottom-content .investors {
    font-family: var(--poppins);
    font-size: 24px;
    font-weight: 700;
    color: #242552;
    margin-bottom: 0;
}

.hero-cards .card-bottom-content .investors-content {
    font-family: var(--poppins);
    font-size: 20px;
    font-weight: 400;
    color: #A7A7A7;
    margin-bottom: 0.5rem;
}

.hero-cards .card-label label {
    color: #3C3E86;
    background-color: #EBECFF;
    padding: 3px 7px;
    border-radius: 5px;
    margin-right: 12px;
    margin-top: 10px;
}

/* VerticalScrollCards.css */
.scrollable-container {
    max-height: 600px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 10px;
    display: flex;
    transform: rotate(5deg);
    flex-direction: column;
    gap: 20px;
}

.cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.scrollable-container-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
}
.carousel-inner-field{
    display: flex;
    gap: 20px;
}

.scrollable-container {
    max-height: 600px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.hero-cards {
    flex: 1 1 45%;
    box-sizing: border-box;
    min-width: 45%;
    max-width: 50%;
}

.hero-cards .card {
    border-radius: 19px;
    margin-left: auto;
    padding: 20px;
}

.hero-cards .card .card-img {
    border-top-left-radius: 19px;
    border-top-right-radius: 19px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.hero-cards .card-content {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 500;
    color: #949494;
    margin-bottom: 0.5rem;
}

.hero-cards .card-bottom-content {
    padding: 20px;
}

.hero-cards .card-bottom-content .investors {
    font-family: var(--poppins);
    font-size: 24px;
    font-weight: 700;
    color: #242552;
    margin-bottom: 0;
}

.hero-cards .card-bottom-content .investors-content {
    font-family: var(--poppins);
    font-size: 20px;
    font-weight: 400;
    color: #a7a7a7;
    margin-bottom: 0.5rem;
}

.hero-cards .card-label label {
    color: #3c3e86;
    background-color: #ebecff;
    padding: 3px 7px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.all-blogs-section {
    border-radius: 40px;
}

.all-blogs-section .heading {
    font-family: var(--font-inter);
    font-size: 24px;
    font-weight: 600;
    color: var(--primary-blue);
}
.blog-posts-news{
    margin: 0px 200px;
}
.fund-section .blog-posts-news{
    margin: 0px ;
}
.fund-section .box-news{
    padding-right: 0px !important;
    padding-left: 0px !important;
}


.display-show-mobile{
    display: none;
}
.all-blogs-section .blog-posts-news .blogs-img {
    width: 100%;
    border-radius: 15px;
    height: 250px;
    overflow: hidden;
    display: flex;
    border: 1px solid #242552;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.all-blogs-section .blog-posts .blogs-img {
    width: 100%;
    border-radius: 15px;
    height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.blog-details-section {
    border-radius: 40px;
}

/* Contact us page */
.contact-form .contact-form-inputs label {
    font-family: var(--font-inter);
    font-size: 18px;
    font-weight: 400;
    color: var(--primary-blue);
}
.contact-us-bottom {
    /* background: url('../../../public/assets/images/contact-us-bg.png'); */
    background-position: center;
    background-repeat: no-repeat;
    padding: 40px;
    width: 50%;
    max-width: 50%;
    min-height: 450px;
}
.under-devlopment-main{
    margin-top: 40px;
    background: url('../../../public/assets/images/under-devlopment-bg.png');
    background-position: 100% 100%;
}
.under-devlopment-main-text{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.under-devlopment-main-text h2{
    text-align: center;
    color: #242552;
    margin-bottom: 20px;
    font-family: var(--font-inter);
    font-size: 31px;
    font-weight: 700;
}
.under-devlopment-main-text h6{
    text-align: center;
    color: #000000;
    font-family: var(--font-inter);
    font-size: 15px;
    font-weight: 700;
}
.under-devlopment-main-text h1{
    text-align: center;
    color: #555555;
    font-family: var(--font-inter);
    font-size: 15px;
    margin-bottom: 20px;
    font-weight: 400;
}
.contect-form-main{
    margin-top: 40px;
    gap: 40px;
    display: flex;
    background: url('../../../public/assets/images/contect_bg.png');
}
.contact-form{
    width: 50%;
    max-width: 50%;
}
.contact-us-bottom h6.subheading {
    font-family: var(--font-poppins);
    font-size: 24px;
    font-weight: 400;
    color: var(--primary-dark);
}

.contact-us-bottom h1.heading {
    font-family: var(--font-poppins);
    font-size: 18px;
    font-weight: 400;
    color: #C9C9C9;
    margin-top: 20px;
}

.contact-us-bottom h6 {
    font-family: var(--font-poppins);
    font-size: 22px;
    font-weight: 600;
    color: var(--primary-dark);
    margin-top: 20px;
}

.contact-us-bottom h6.heading {
    font-family: var(--font-inter);
    font-size: 22px;
    font-weight: 600;
    color: var(--primary-dark);
    position: relative;
    padding-bottom: 20px;
}

.contact-us-bottom h6.heading::after {
    content: "";
    display: block;
    width: 10%;
    height: 3px;
    background-color: var(--primary-dark);
    position: absolute;
    bottom: 0;
    left: 0;
}

.contact-us-bottom p {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 400;
    color: var(--primary-dark);
    margin-top: 20px;
    line-height: 32px;
}
.contact-form .contact-form-inputs .input-box {
    border: 1px solid #DDDDDD;
    background-color: #F6F6F6;
    border-radius: 12px;
    padding: 8px 20px;
    font-family: var(--font-inter);
    font-size: 18px;
    font-weight: 400;
    color: #8D8D8D;
    margin-top: 5px;
}

.contact-form .contact-form-inputs textarea.input-box {
    border: 1px solid #DDDDDD;
    background-color: #F6F6F6;
    border-radius: 12px;
    padding: 10px 20px;
    font-family: var(--font-inter);
    font-size: 22px;
    font-weight: 400;
    color: var(--primary-blue);
    margin-top: 5px;
}

.contact-form .contact-form-inputs .input-box::placeholder {
    font-family: var(--font-inter);
    font-size: 14px;
    font-weight: 400;
    color: #8D8D8D;
}

.contact-form .contact-form-inputs .message-btn {
    font-family: var(--font-inter);
    font-size: 15px;
    font-weight: 600;
    background-color: var(--primary-blue);
    color: var(--white);
    padding: 20px 30px;
    border-radius: 10px;
    border: none;
}

.blog-details-section .icon-img {
    font-size: 20px;
    font-weight: 600;
    font-family: var(--font-inter);
    color: var(--primary-blue);
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
}

.blog-details-section .icon-img img {
    height: 20px;
    width: 20px;
    margin: 0 5px 0 0;
}

.blog-details-section img {
    height: 500px;
    margin: auto;
    display: flex;
    border-radius: 33px;
}

.blog-details-section .subheading {
    font-size: 24px;
    font-weight: 600;
    font-family: var(--font-inter);
    color: var(--primary-blue);
}
.blog-details-section .heading-subheading{
    font-size: 26px;
    font-weight: 700;
    background: linear-gradient(90.66deg, #5153B8 2.82%, #9FB4FF 56.58%, #D2DCFF 89.22%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: var(--font-inter);
    color: var(--primary-dark);
    margin-top: 15px;
    margin-bottom: 15px;
}
.blog-details-section .heading {
    font-size: 36px;
    font-weight: 700;
    font-family: var(--font-inter);
    color: var(--primary-dark);
    margin-top: 15px;
    margin-bottom: 15px;
}

.blog-details-section .content {
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
    font-family: var(--font-inter);
    color: #505050;
    margin-top: 10px;
}

.all-blogs-section .blog-posts-news .blogs-img img {
    object-fit: contain;
    object-position: top;
}

.all-blogs-section .blog-posts-news .blogs-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.all-blogs-section .blog-posts-news h6.heading {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-blue);
}

.all-blogs-section .blog-posts-news .blogs-content h6.subheading {
    font-family: var(--font-inter);
    font-size: 28px;
    font-weight: 600;
    color: #1A1A1A;
    margin-top: 5px;
}

.all-blogs-section .blog-posts-news .content {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-blue);
    width: 90%;
    margin-top: 12px;
}
.all-blogs-section .blog-posts .blogs-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.all-blogs-section .blog-posts .blogs-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.all-blogs-section .blog-posts h6.heading {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 600;
    color: var(--primary-blue);
}

.all-blogs-section .blog-posts .blogs-content h6.subheading {
    font-family: var(--font-inter);
    font-size: 24px;
    font-weight: 600;
    color: #1A1A1A;
    margin-bottom: 0;
    margin-top: 5px;
}

.all-blogs-section .blog-posts .content {
    font-family: var(--font-inter);
    font-size: 16px;
    font-weight: 400;
    color: #667085;
    width: 90%;
    margin-top: 12px;
}
.tabs-layout .nav-tabs {
    border-bottom: none;
}
.pagination-controls .btn {
    background-color: transparent;
    border: none;
    color: #667085;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-inter);
}

.pagination-controls .btn.active {
    color: #7F56D9;
}

.pagination-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #EAECF0;
    padding: 20px 10px 0px 10px;
}

.tabs-layout .nav-item p {
    font-family: var(--font-inter);
    font-size: 15px;
    font-weight: 500;
    color: #929292;
}

.tabs-layout .nav-item {
    width: 33.00%;
}

.tabs-layout .nav-link {
    border: 3px solid #C9C9C9;
    border-radius: 15px;
    width: 90%;
}

.tabs-layout .nav-link.active {
    position: relative;
    border-radius: 15px;
    background-color: transparent;
}

.tabs-layout .nav-link.active::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    padding: 3px;
    background: linear-gradient(101.1deg, #3C3E86 0%, #4349FF 48.92%, #DEF4FF 100%);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
}

.tabs-layout .nav-link.active .inner-content {
    position: relative;
    z-index: 1;
    background-color: transparent;
}

.tabs-layout .nav-link .heading {
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-dark);
}

.tabs-layout .nav-link .subheading {
    font-family: var(--font-inter);
    font-size: 15px;
    font-weight: 500;
    color: #929292;
}
.hide-desktop{
    display: none;
}
.border-none{
    border: none;
    background-color: transparent;
}
.black-link {
    color: black;
}

@media(max-width:768px) {
    .auto-card-scroll .card-column {
        height: 30vh;
        transform: rotate(0deg);
    }
    .fund-section .carousel{
        display: flex;
        margin-top: 10px;
    }
    .blog-details-section img{
        height: auto;
    }
    .main-layout .navbar{
        height: 100%;
    }
.button-margin-mobile{
    margin-top: 10px;
}
.main-layout .navbar-collapse {
    flex-grow: 1;
    justify-content: space-between;
    z-index: 1;
    background-color: var(--white);
}
    .investments-on-bizdateup .subheading{
        text-align: center;
        font-size: 18px;
    }
    .contact-form-inputs .mt-5{
        margin-top: 15px !important;
    }
    .blog-posts-news{
        margin: 0px ;
    }
    .box-news{
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .hide-desktop{
        display: block;

    padding-bottom: 30px;
    }
    .hide-mobile{
        display: none;
    }
    .auto-card-scroll .card-scroll-container {
        gap: 40px;
        margin-top: 0px;
    }
    .fund-section .carousel{
        gap: 10px;
    }
    .contect-form-main{
        display: flex;
        gap: 20px;
        flex-wrap: wrap-reverse;
    }
    .under-devlopment-main-text img{
        width: 400px;
    }
    .under-devlopment-main-text h2{
        font-size: 24px;
        margin-bottom: 10px;
    }
    .under-devlopment-main-text h1{
        font-size: 12px;
        margin-bottom: 10px;
    }
    .under-devlopment-main-text h6{
        font-size: 12px;
    }
    .contact-us-bottom{
        width: 100%;
        max-width: 100%;
        padding: 0px;
    }
    .contact-form{
        width: 100%;
        max-width: 100%;
    }
    .blog-section-carousel .carousel{
        gap: 10px;
    }
    .carousel-inner-field{
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: auto;
        gap: 0px;
    }
    .carousel-inner-arrow p{
        display: none;
    }
    .carousel-inner-arrow{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .investments-on-bizdateup .tab-content{
        margin-top: 20px;
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .investments-on-bizdateup{
        margin: 0px 0;
        background-color: var(--white);
    }
    .fund-section .card{
        padding: 0px;
        min-height: 280px;
    }
    .fund-section .all-blogs-section .card{
        padding: 0px;
        min-height: 305px;
    }
    .investor-section .heading{
        margin-top: 20px;
        line-height: 40px;
    }

    .auto-card-scroll .card-img {
        width: 100px;
        height: 100%;
        border-radius: 8px;
    }
    .nav-tabs .nav-link{
        margin-bottom: 10px;
    }
    .investment-tabs-container .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
        padding: 10px 15px 0px 15px;
    }

    .investor-section .subheading {
        font-size: 50px;
        line-height: 35px;
    }

    .investor-section .subtext {
        font-size: 22px;
    }

    .fund-section .heading {
        font-size: 28px;
    }

    .profit-section .heading {
        font-size: 28px;
    }

    .fund-section .content {
        font-size: 18px;
    }

    .profit-section .content {
        font-size: 18px;
    }
    .fund-section .card{
        margin-top: 10px;
    }
    
    .faq-section .any-question {
        margin-top: 30px;
    }

    .fund-section .subcontent {
        margin-bottom: 20px;
    }

    .profit-section .profit-content {
        margin-top: 30px;
    }

    .profit-section .profit-content .content-heading {
        font-family: var(--font-lato);
        font-size: 25px;
        font-weight: 700;
        font-style: italic;
        line-height: 32.89px;
        color: #E33845;
        width: 100%;
        margin: 30px auto 20px;
        text-align: center;
    }


    .profit-section .subheading,
    .opportunities-section .subheading {
        font-size: 24px;
    }

    .profit-section .subcontent {
        font-size: 18px;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .profit-section .btn {
        font-size: 16px;
    }

    .main-layout .future-of-startups .buttons {
        flex-direction: column !important;
    }

    .main-layout .hero-section .future-of-startups {
        min-height: 80vh;
    }

    .main-layout .future-of-startups h1 {
        font-size: 24px;
        padding-top: 40px;
        line-height: 10px;
    }

    .main-layout .future-of-startups h1 span {
        font-size: 24px;
        line-height: 50px
    }

    .main-layout .future-of-startups p {
        font-size: 16px;
        margin-top: 0px !important;
    }

    .main-layout .future-of-startups .buttons {
        margin-top: 15px;
    }

    .tabs-layout .nav-item {
        width: 100% !important;
        margin-bottom: 20px;
    }

    .tabs-layout .nav-link {
        width: 100% !important;
    }

    .investor-labels {
        padding: 0;
    }


    .investment-section {
        margin: 0px 0;
        padding: 20px 20px;
        background-color: var(--white);
    }

    .opportunities-section {
        padding: 20px;
        margin: 0px;
    }


    .investment-section .heading,
    .portfolio-companies-section .head,
    .deals-section .head {
        font-size: 28px;
    }
    .portfolio-companies-section .mt-5{
        margin-top: 20px !important;
    }
    .portfolio-companies-section .card{
        padding: 0px 0px 10px 0px;
    }

    .deals-section .content {
        font-size: 18px;
        width: 100%;
    }

    .investments-on-bizdateup .content{
        font-size: 18px;
        text-align: center;
    }
    .all-blogs-section .blog-posts-news .content{
        font-size: 14px;
        width: 100%;
    }

    .investment-section .content,
    
    .opportunities-section .content,
    .opportunities-section .subcontent,
    .opportunities-section .btn {
        font-size: 18px;
    }

    .investment-section .img {
        height: 320px;
    }

    .investment-section .subheading{
        font-size: 18px;
        width: 100%;
        text-align: center;
    }
    
    .investments-on-bizdateup .heading,
    .opportunities-section .heading {
        font-size: 28px;
        width: 100%;
        text-align: center;
    }

    .investments-on-bizdateup .nav,
    .opportunities-section .profit-content {
        margin-top: 30px !important;
    }

    .blog-section .head {
        font-size: 28px;
        line-height: 40px;
    }

    .blog-section .recent-blog-posts {
        margin: 30px 0px 0px 0px;
    }

    .investment-section .subcontent {
        font-size: 13px;
        text-align: center;
    }

    .blog-section{
        margin: 0;
    }
    .syndicate-section .heading{
        font-size: 28px;

    }
    .main-layout .startup-hero h1{
        font-size: 28px;
    }
    .main-layout .startup-hero p{
        font-size: 16px;
    }
    .syndicate-section .subheading {
        font-size: 18px;
        text-align: center;
    }

    .syndicate-section .content,
    .syndicate-section .subcontent {
        font-size: 16px;
        text-align: center;
    }

    .syndicate-section {
        margin: 30px 0 0 0;
        padding: 20px 20px;
        background-color: var(--white);
    }

    .blog-section-carousel .carousel-control-next {
        left: auto;
    }

    .investor-section .investor-data {
        margin: 10px 20px 10px 0;
    }


    .profit-section {
        padding: 20px;
        margin: 0px;
    }

    .local-favourite-section {
        padding: 0px;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .local-favourite-section .heading {
        font-size: 28px;
        width: 100%;
    }

    .local-favourite-section .content {
        font-size: 18px;
    }

    .local-favourite-section .local-favourite-section-content {
        margin-top: 30px;
    }

    .local-favourite-section .local-favourite-section-content .label {
        font-size: 16px;
    }

    .why-bizdateup-section .heading {
        font-size: 28px;
        width: 100%;
    }

    .why-bizdateup-section .content {
        font-size: 18px;
    }

    .why-bizdateup-section-div {
        margin-top: 0px !important;
    }

    .why-bizdateup-section .subheading {
        font-size: 18px;
        width: 100%;
    }

    .why-bizdateup-section .subcontent {
        font-size: 14px;
        width: 100%;
    }

    .why-bizdateup-section {
        padding: 20px;
        margin: 0px;
    }

    .risk-section {
        padding: 20px;
        margin: 0px;
    }

    .risk-section .subheading,
    .faq-section .heading {
        font-size: 28px;
    }

    .risk-section .subcontent,
    .faq-section .content {
        font-size: 18px;
    }

    .blog-section-carousel {
        padding: 20px;
    }

    .investments-on-bizdateup {
        padding: 20px;
    }
    .main-layout .startup-hero h1{
        line-height: 40px;
        padding-top: 20px;
    }
    .faq-section {
        padding: 20px 20px 20px 20px;
        margin: 0px;
    }

    .footer {
        padding: 100px 20px 30px 20px;
    }

    .footer .link {
        margin-top: 10px;
    }

    .hero-section {
        padding: 0 20px;
    }
    .investor-section{
        padding: 0 20px 20px 20px;
    }
    .profit-section .mb-5{
        margin-bottom: 30px !important;
    }
    .main-layout .future-of-startups .start-investing-btn {
        margin-right: 0px;
    }

    .faq-section .accordion {
        margin-top: 30px;
        padding: 0px;
    }

    .faq-section .accordion-button {
        font-size: 18px;
    }

    .faq-section .accordion-body {
        font-size: 16px;
    }

    .investor-label .investor-data {
        margin: 10px 20px 10px 0
    }
    .profitsection-slider-image{
        height: auto;
    }

    .fund-section .card-img {
        width: 100%;
        height: auto;
    }

    .blog-label .blog-data {
        margin: 10px 20px 10px 0;
    }

    .blog-label .blog-data.active {
        margin: 10px 20px 10px 0;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .profit-section .profit-content .content-heading {
        font-family: var(--font-lato);
        font-size: 25px;
        font-weight: 700;
        font-style: italic;
        line-height: 32.89px;
        color: #E33845;
        width: 40%;
        margin: 30px auto 20px;
        text-align: center;
    }

}

@media(max-width:1200px) {
    .main-layout .hero-section .future-of-startups {
        min-height: 50vh;
        padding: 0px 0px 30px 0px;
    }

    .navbar-responsive {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 92px;
    }

    .main-layout .future-of-startups .buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .local-favourite-section .local-favourite-section-content .card {
        border-radius: 19px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        border: none;
        margin-top: 20px;
    }

    .local-favourite-section .carousel-indicators {
        text-align: center;
        width: 100%;
        margin-left: auto;
        margin-right: 0;
        bottom: -50px;
    }

    .blog-details .img {
        border-radius: 16px;
        width: auto;
    }

    .blogs-section {
        padding: 20px;
    }

    .blog-details {
        padding: 0 20px;
    }
}

.text-part{
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 700;
    color: #fff!important;
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    background-color: #242552!important;
text-decoration: none;
background-color: #fff;
width: 100%!important;
padding: 10px;
display:block;

}
.custom-absolute-text{
    top: -45px;
    z-index: 10000;
    position: absolute;
    right: 0px;
}
.border-circle{
    border-radius: 20px;
    padding: 10px;
}
.border-radius-change{
    border-radius: 10px;
}
.bg-changed{
    background-color: #33425B;
}
.changed-pad{
    padding: 1px 10px !important;
}
.changed-close{
    font-weight: 600 !important;
    font-size: 15px;
    color: #000 !important;
    margin-right: 11px;
    margin-top: 8px;
    margin-bottom: 10px;

}
.bg-body-change{
    background-color: #ffffff!important;
}
.closebtn{
    font-family: var(--font-inter);
    font-size: 20px;
    font-weight: 500;
    color: #fff !important;
    padding: 10px 15px !important;
    border-radius: 8px;
    border: none;
    background-color: #000000 !important;
    text-decoration: none;
    /* display: block; */
    height: 50px;
    margin-top: 7px;
}